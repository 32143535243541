(function ($) {
  jQuery(document).ready(function () {
    $(".testimonials-cards-carousel").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      /* autoplay: true, */
      autoplaySpeed: 4000,
      prevArrow: $(".testimonials-prev-arrow"),
      nextArrow: $(".testimonials-next-arrow"),
      responsive: [
        {
          breakpoint: 1450,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });
})(jQuery);
