(function ($) {
  jQuery(document).ready(function () {
    $( document ).on( 'click', '.content_list', function () {
      if ($(this).find(".content_list_description").hasClass( 'show-text' ) ) {
        $(this).find(".content_list_description").hide();
        $(this).find(".content_list_description").removeClass( 'show-text' );
        $(this).find("i").addClass("fa-plus");
        $(this).find("i").removeClass("fa-minus");
        $(this).removeClass("active");
        $(this).attr("aria-expanded", false);
      } else {
        $(this).find(".content_list_description").show();
        $(this).find(".content_list_description").addClass( 'show-text' );
        $(this).find("i").removeClass("fa-plus");
        $(this).find("i").addClass("fa-minus");
        $(this).addClass("active");
        $(this).attr("aria-expanded", true);
      }
    });
  });
})(jQuery);
