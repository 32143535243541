jQuery(function ($) {
  // improved gform ajax spinner
  $(document).on("submit", ".gform_wrapper form", function () {
    let $button = $("button[type=submit]", this);
    if (!$button.length) {
      return;
    }
    $button.attr("disabled", true);
    $button.attr("aria-disabled", "true");
    $button.addClass("is-ajax-submitting");
  });

  function init_select2(element) {
    let $element = $(element);
    let $gf_placeholder = $("option.gf_placeholder", $element);
    if ($gf_placeholder.length) {
      $element.attr("data-placeholder", $gf_placeholder.text());
      $gf_placeholder.html("").removeClass("gf_placeholder");
    }
    $element.select2({
      minimumResultsForSearch: 5,
      closeOnSelect: $(element).data().close_on_select ?? true,
      allowClear: $element.attr("aria-required") != "true",
    });
  }

  $("select")
    .not(".no-select2")
    .each(function () {
      //select-default-class
      console.log(this);
      init_select2(this);
    });

  $(document).on("gform_post_render", function (e, form_id, current_page) {
    $("select", `#gform_${form_id}`).each(function () {
      init_select2(this);
    });
  });
});
