jQuery(document).ready(function ($) {
  filterPublication();

  //AJAX ON CLICK OR SEARCH
  $(".categories-option").click(function () {
    $(".categories-option").removeClass("active");
    $(this).addClass("active");
    filterPublication();
  });

  $(".search-button").click(function () {
    searchPublication();
  });

  $(".clear").click(function () {
    $($(".categories-option")[0]).addClass("active");
    $("#filter_search").val("");
    filterPublication();
  });

  $("#filter_search").on("keypress", function (e) {
    if (e.which == 13) {
      searchPublication();
    }
  });

  function searchPublication() {
    if (!$("#filter_search").val()) return;
    $(".categories-option").removeClass("active");
    ThemeJS.Functions.send_ajax_request({
      data: {
        search: $("#filter_search").val(),
        action: "filter_publication",
      },
      success: (response) => {
        if (response) {
          $(".publication-list").html(response);
        } else {
          $(".publication-list").html(
            '<p class="text-center">No results found</p>'
          );
        }
      },
    });
  }

  function filterPublication() {
    $("#filter_search").val("");
    if (!$(".categories-option.active")) {
      $($(".categories-option")[0]).addClass("active");
    }
    ThemeJS.Functions.send_ajax_request({
      data: {
        action: "filter_publication",
        type: $(".categories-option.active").data("type"),
      },
      success: (response) => {
        if (response) {
          $(".publication-list").html(response);
        } else {
          $(".publication-list").html(
            '<p class="text-center">No results found</p>'
          );
        }
      },
    });
  }
});
