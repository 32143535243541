(function ($) {
  jQuery(document).ready(function () {
    $(".slider-carousel").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      infinite: false,
      /* autoplay: true, */
      autoplaySpeed: 4000,
      prevArrow: $(".slider-prev-arrow"),
      nextArrow: $(".slider-next-arrow"),
    });
  });
})(jQuery);
